import React from 'react';
import { graphql } from 'gatsby';
import Path from '../../services/path';
import Layout from '../../components/layout/layout';
import SEO from '../../components/seo';
import Container from '../../components/container';
import CatchupHelper from '../../services/catchup-filter-service';
import SlimlineBannerBlock from '@bfbs/storybook/dist/components/molecules/slimline-banner-block/slimline-banner-block';
import CatchupShow from '@bfbs/storybook/dist/components/organisms/catchup-show/catchup-show';
import Time from "../../services/time";

const CatchupPage = ({ data: { allBroadcast } }) => {
  const allCatchUpBroadcasts = CatchupHelper.getCatchupBroadcasts(allBroadcast.nodes);
  const broadcasts = CatchupHelper.distinctByListenAgainUrl(allCatchUpBroadcasts);
  const latestEpisode = broadcasts[0];
  const moreEpisodes = broadcasts.slice(1);

  const breadcrumbs = [
    { name: 'Radio', path: Path.basePath },
    { name: 'Catch up', path: Path.catchupList },
    { name: latestEpisode.title, path: null },
  ];

  /**
   * Builds the start date.
   *
   * @param broadcast
   * @param broadcast.start
   * @returns {string}
   */
  const buildStartDate = (broadcast) => {
    return Time.prepareTimezone(broadcast.start).format('DD/MM/YYYY');
  }

  /**
   * Create an array of older episodes to use in the component.
   *
   * @param broadcasts
   * @returns {[]}
   */
  const prepareMoreEpisodes = broadcasts => {
    const episodes = [];

    broadcasts.forEach(broadcast => {
      episodes.push({
        title: buildStartDate(broadcast),
        playUrl: Path.getCatchupPlayerUrl(broadcast.id),
        duration: broadcast.duration,
        content: broadcast.description,
        icon: 'play-btn-blue-white'
      });
    });

    return episodes;
  };

  return (
    <Layout breadcrumbs={breadcrumbs}>
      <SEO title={latestEpisode.title} />

      <SlimlineBannerBlock background="background--tertiary-two" heading="Catch up" />

      <Container>
        <CatchupShow
          description={latestEpisode.description}
          ctaUrl="/catchup"
          episodes={!CatchupHelper.onlyShowLatest(latestEpisode) ? prepareMoreEpisodes(moreEpisodes) : []}
          listenNowData={{
            date: buildStartDate(latestEpisode),
            href: Path.getCatchupPlayerUrl(latestEpisode.id),
            modifier: 'rounded',
            text: 'Listen now',
            title: latestEpisode.title,
            duration: latestEpisode.duration,
          }}
        />
      </Container>
    </Layout>
  );
};

export default CatchupPage;

export const catchupQuery = graphql`
  query CatchUpBroadcast($show: String!) {
    allBroadcast(
      filter: {
        title: { eq: $show }
        field_values: { listen_again: { eq: true }, listen_again_url: { regex: "/^http(|s)://.+(.mp3|.aac).*$/i" } }
        published: { eq: true }
      }
      sort: { order: DESC, fields: stop___date }
    ) {
      nodes {
        id
        title
        description
        bfbs_id
        short_name
        duration
        field_values {
          listen_again_url
        }
        stop {
          date
          timezone
        }
        start {
          date
          timezone
        }
      }
    }
  }
`;
